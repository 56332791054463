.container {
    width: 100%;
}

@media (min-width: 480px) {
    .container {
        max-width: 480px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 976px) {
    .container {
        max-width: 976px;
    }
}

@media (min-width: 1440px) {
    .container {
        max-width: 1440px;
    }
}
/* Universal css here*/
.flex-col-reverse{
    flex-direction: column-reverse;
}
.flex-col{
    flex-direction: column;
}
.h-14 {
    height: 3.5rem;
}

@media (min-width: 480px) {

}

@media (min-width: 768px) {
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:w-40\%{
        width: 35%;
    }
}

@media (min-width: 976px) {

}

@media (min-width: 1440px) {

}