.service-card-padding{
    padding: 2rem;
}
.service-card-overlap{
    margin-bottom: -15px;
}
.service-card-overlapped{
    z-index: -1;
    margin-top: -15px;
}
@media (min-width: 768px) {
    .md\:overlapped {
        z-index: -1;
        margin-left: -100px;
        margin-top: 0;
    }
    .md\:overlap {
        margin-left: 100px;
        margin-bottom: 0;
    }
    .md\:overlap div{
        width:20rem;
    }
    .md\:service-card-content-padding{
        padding: 24px 24px 24px 8rem;
        width: 80%;
    }
}