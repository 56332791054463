@font-face {
    font-family: "Jenthill";
    src: url("../assets/fonts/Jenthill.ttf") format('truetype'), url("../assets/fonts/Jenthill.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Century Gothic";
    src: url('../assets/fonts/CenturyGothic.ttf') format('truetype'), url('../assets/fonts/CenturyGothic.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Century Gothic";
    src: url('../assets/fonts/CenturyGothic-Bold.ttf') format('truetype'), url('../assets/fonts/CenturyGothic-Bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Century Gothic";
    src: url('../assets/fonts/CenturyGothic-Italic.ttf') format('truetype'), url('../assets/fonts/CenturyGothic-Italic.ttf') format("truetype");
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: "Century Gothic";
    src: url('../assets/fonts/CenturyGothic-BoldItalic.ttf') format('truetype'), url('../assets/fonts/CenturyGothic-BoldItalic.ttf') format("truetype");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: "Plug Display";
    src: url("../assets/fonts/Plug-Display.ttf") format('truetype'), url("../assets/fonts/Plug-Display.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}