
@import url('http://fonts.cdnfonts.com/css/jenthill');
@media (min-width: 768px) {
    .Card__link:after {
        content: "";
        margin-top: 1.5rem;
        position: absolute;
        width: 20%;
        height: 2px;
        background-color: white;
        top: 0.75rem;
        right: calc(100% + 2rem);
    }
    .Card__link {
        width: 65%;
        position: relative;
    }
}