@import "./utils/utilStyle.css";
@import "./container/import.css";
@import "./utils/font.css";

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
}
body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Century Gothic",sans-serif;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px hsl(321,64%,60%);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: hsl(321,64%,56%);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: hsl(321,64%,40%);
}
/*svg animation*/
.line-container{
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

svg{
    display: inline-block;
    height: 100%;
}